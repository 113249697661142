import React from 'react';
import Section from '../../molecules/Section/Section';
import { colors } from '../../styles/atoms/colors';
import OptimizedImage from '../../utils/OptimizedImage';
import {
  Digits,
  Heading,
  HeadingWrapper,
  Kicker,
  Label,
  MetricItem,
  MetricsContainer,
  MetricsContent,
  Subhead,
  Wrapper,
} from './Metrics.styles';

const Metrics = ({
  component: {
    id,
    bgColorString,
    image,
    headingKicker,
    heading,
    headingColor,
    headingSize,
    subhead,
    metricsList,
    internalName,
  },
}) => {
  const hasImage = image?.image;

  return (
    <Section
      id={id}
      bgColor={
        bgColorString === 'light_gray' ? colors.base.light : colors.base.white
      }
    >
      <Wrapper>
        <HeadingWrapper>
          <Kicker>{headingKicker}</Kicker>
          <Heading
            className={headingSize}
            style={{ color: headingColor?.hex || '#1d2030' }}
          >
            {heading}
          </Heading>
          {subhead && <Subhead>{subhead}</Subhead>}
        </HeadingWrapper>
        <MetricsContent split={hasImage}>
          <MetricsContainer split={hasImage}>
            {metricsList.map(metric => (
              <MetricItem
                key={metric.id}
                spacing={Boolean(metric.description && image?.image)}
              >
                {metric.heading && <Digits>{metric.heading}</Digits>}
                {metric.subhead && (
                  <Label dangerouslySetInnerHTML={{ __html: metric.subhead }} />
                )}
                {metric.description && (
                  <p className="description">{metric.description}</p>
                )}
              </MetricItem>
            ))}
          </MetricsContainer>
          {image?.image && (
            <div className="metrics-image">
              <OptimizedImage
                image={image?.image?.gatsbyImageData}
                src={image?.image?.url}
                alt={`Metrics image - ${internalName}`}
              />
            </div>
          )}
        </MetricsContent>
      </Wrapper>
    </Section>
  );
};

export default Metrics;
