import styled, { css } from 'styled-components';
import { atMinWidth } from '../../styles/atoms/breakpoints';
import { colors } from '../../styles/atoms/colors';
import { font } from '../../styles/atoms/typography';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 40px;
  text-align: center;
  width: 100%;

  ${atMinWidth.sm`
    gap: 48px;
  `}

  ${atMinWidth.md`
    gap: 56px;
  `}

  ${atMinWidth.sm`
    gap: 64px;
  `}
`;

export const HeadingWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 24px;
  text-align: center;
`;

export const Kicker = styled.p`
  ${font('overline', 'sm', '700')}
  color: ${colors.primary[500]};
`;

export const Heading = styled.h2`
  ${font('display', 'md', '700')}
  color: ${colors.gray[900]};

  ${atMinWidth.sm`
    ${font('display', 'lg', '700')}
  `}

  ${atMinWidth.md`
    ${font('display', 'xl', '700')}
  `}

  &.XXLarge {
    ${font('display', 'lg', '700')}

    ${atMinWidth.sm`
      ${font('display', 'xl', '700')}
    `}

    ${atMinWidth.md`
      ${font('display', 'xxl', '700')}
    `}
  }

  /* Default is XLarge */
  &.XLarge {
    ${font('display', 'md', '700')}

    ${atMinWidth.sm`
      ${font('display', 'lg', '700')}
    `}

    ${atMinWidth.md`
      ${font('display', 'xl', '700')}
    `}
  }

  &.Large {
    ${font('display', 'sm', '700')}

    ${atMinWidth.sm`
      ${font('display', 'md', '700')}
    `}

    ${atMinWidth.md`
      ${font('display', 'lg', '700')}
    `}
  }

  &.Medium {
    ${font('display', 'sm', '700')}

    ${atMinWidth.sm`
      ${font('display', 'md', '700')}
    `}
  }

  &.Small {
    ${font('display', 'xs', '700')}

    ${atMinWidth.sm`
      ${font('display', 'sm', '700')}
    `}
  }

  &.XSmall {
    ${font('display', 'xs', '700')}
  }
`;

export const Subhead = styled.p`
  ${font('text', 'md', '400')}
  color: ${colors.gray[600]};

  ${atMinWidth.sm`
    ${font('text', 'lg', '400')}
  `}

  ${atMinWidth.md`
    ${font('text', 'xl', '400')}
  `}
`;

export const Digits = styled.p`
  ${font('display', 'lg', '700')}
  color: ${colors.primary[500]};

  ${atMinWidth.lg`
    ${font('display', 'xl', '700')}
  `}
`;

export const MetricsContent = styled.div`
  display: flex;
  flex-flow: column;
  gap: 12px;
  width: 100%;

  ${atMinWidth.lg`
   ${props =>
     props.split &&
     css`
       flex-direction: row;

       & > div {
         flex: 1 0 45%;
       }
     `}
  `}
`;

export const MetricsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  gap: 24px 64px;
  width: 100%;

  ${atMinWidth.sm`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${atMinWidth.lg`
    grid-template-columns: ${props =>
      props.split ? 'repeat(2, 1fr)' : 'repeat(4, 1fr)'}
  `}
`;

export const MetricItem = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: ${props => (props.spacing ? '16px' : '24px')};
  text-align: center;
  max-width: 200px;
`;

export const Label = styled.div`
  p {
    ${font('overline', 'sm', '700')}
    color: ${colors.gray[600]};
  }
`;

export const MetricDescription = styled.p`
  ${font('text', 'sm', '400')}
  color: ${colors.gray[600]};
`;
